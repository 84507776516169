import React, { useState, useEffect, useCallback } from 'react';
import T from 'prop-types';
// redux
import { updatePigGroup } from 'reducers/pigGroups/pigGroupShow';
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// components
import ButtonRadioGroup from 'components/ButtonRadioGroup/ButtonRadioGroup';
import { FormattedMessage } from 'react-intl';
import AsyncSelectLoadMore from 'components/AsyncSelect/AsyncSelectLoadMore';
import Button, { TransparentBtn } from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import ReactSelect from 'components/Select/ReactSelect';
import SourcesSelector from '../components/SourcesSelector';
import SingleDatePicker from 'components/DatePicker/SingleDatePicker/SingleDatePicker';
import CircleCheckbox from 'components/CircleCheckbox';
// api
import { searchAdminFarms } from 'endpoints/admin/farms';
// hooks
import useCompanies from 'hooks/useCompanies';
// utils
import moment from 'moment';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatFarmOptionLabel, showToastrMessage } from 'utils';
import {
  getDefaultPigGroupSources,
  preparePigGroupSources,
  formatPigGroupSources,
  isSameExternalSources,
} from 'utils/pigGroupsHelper';
// styles
import styles from './GroupInfo.module.scss';

function getPigGroupState(pigGroup) {
  if (!pigGroup?.id) return {};
  return {
    pigGroupId: pigGroup?.id,
    creationDate: moment(pigGroup.created_on),
    company: pigGroup.company,
    farm: { value: pigGroup.farm?.id, label: formatFarmOptionLabel(pigGroup.farm || {}) },
    loose_sow_housed: pigGroup.loose_sow_housed,
    abf_tracking: pigGroup.abf_tracking,
    sources: pigGroup.source_entities?.length
      ? formatPigGroupSources(pigGroup.source_entities)
      : getDefaultPigGroupSources(),
  };
}

const isSourcesInvalid = (sources = []) => sources.some((source) => {
  // if farm is selected, but group doesn't - return false
  return source.entity_type === 'internal' && source.farm?.id && !source.source_pig_group?.label;
});

const abfOptions = [
  { label: <FormattedMessage id="general.button.no" />, value: false },
  { label: <FormattedMessage id="general.button.yes" />, value: true },
];

const GroupInfo = ({
  isLogisticsEnabled,
  isLoading,
  openModal,
  params,
  pigGroup,
  updatePigGroup,
  isABFEnabled,
}) => {
  const [isSourcesEditable, setIsSourcesEditable] = useState(!isLogisticsEnabled);
  const [pigGroupState, setPigGroupState] = useState(getPigGroupState(pigGroup));
  const { isLoading: isCompaniesLoading, companies } = useCompanies();

  useEffect(() => {
    if (pigGroup?.id && pigGroupState.pigGroupId !== pigGroup?.id) {
      setPigGroupState(getPigGroupState(pigGroup));
    }
  }, [pigGroup?.id]);

  const onGroupInfoChange = (key) => (fieldData) => {
    setPigGroupState((prevState) => ({ ...prevState, [key]: fieldData || {} }));
  };

  const onGroupAbfChange = ({ target: { value: abf_tracking } }) => {
    setPigGroupState((prevState) => ({ ...prevState, abf_tracking: JSON.parse(abf_tracking) }));
  };

  const onGroupLooseSowHousedToggle = () => {
    setPigGroupState((prevState) => ({ ...prevState, loose_sow_housed: !prevState.loose_sow_housed }));
  };

  const onSubmit = () => {
    const { creationDate, company, farm, sources, loose_sow_housed, abf_tracking } = pigGroupState;

    updatePigGroup({
      created_on: creationDate.format('YYYY-MM-DD'),
      farm_id: farm.value,
      company_id: company.id,
      source_entities_attributes: preparePigGroupSources(sources),
      abf_tracking,
      loose_sow_housed,
    }, params.id)
      .then(({ value: group }) => {
        showToastrMessage('component.toastr.groupInfo.wasUpdated');
        if (isLogisticsEnabled) setIsSourcesEditable(false);
        setPigGroupState((prevState) => ({
          ...prevState,
          sources: group.source_entities?.length
            ? formatPigGroupSources(group.source_entities)
            : getDefaultPigGroupSources(),
        }));
      })
      .catch(toastResponseErrors);
  };

  const isSameGroupInfo = () => {
    const { creationDate, farm, company, sources } = pigGroupState;
    const { created_on, company_id, farm_id, source_entities } = pigGroup;
    const isSameDay = moment(creationDate).isSame(created_on, 'day');
    const isSameSources = isSameExternalSources(sources, source_entities);
    const isSameCompany = company?.id === company_id;
    const isSameFarm = farm?.value === farm_id;
    const isSameABFTracking = pigGroupState.abf_tracking === pigGroup.abf_tracking;
    const isSameLSHValue = pigGroupState.loose_sow_housed === pigGroup.loose_sow_housed;
    return (isSameDay && isSameCompany && isSameFarm && isSameSources && isSameLSHValue && isSameABFTracking);
  };

  const handleConfirmSourcesEdit = () => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.editSource" />}
        actionBtnLabel={<FormattedMessage id="component.modal.editSource.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.editSource.warning" />}
        handleConfirm={() => setIsSourcesEditable(true)}
      >
        <FormattedMessage id="component.modal.editSource.text" />
      </ConfirmationModal>
    );
  };

  const getInternalFarms = useCallback((params) => {
    return searchAdminFarms({ ...params, only_internal: true });
  }, []);

  const isSavingDisabled =
    !pigGroup?.id
    || isLoading
    || isSameGroupInfo()
    || isSourcesInvalid(pigGroupState.sources);

  const isABFFieldVisible = isABFEnabled || pigGroup.abf_tracking;

  return (
    <section className="small-12 column">
      <Preloader isActive={isLoading} />
      <Panel>
        <Panel.Body noPadding>
          <div className={styles['group-field']}>
            <div className={styles.title}>
              <FormattedMessage id="component.groupCreate.groupCreationDate" />
            </div>
            <SingleDatePicker
              date={pigGroupState.creationDate || null}
              onDateChange={onGroupInfoChange('creationDate')}
              inputLabel="MM/DD/YYYY"
              isOutsideRange={(day) => (day.isAfter(moment(), 'day'))}
              enableOutsideDays
            />
          </div>
          <div className={styles['group-field']}>
            <div className={styles.title}>
              <FormattedMessage id="component.groupCreate.ownership" />
            </div>
            <ReactSelect
              noInputMargin
              className="small-12 medium-6"
              valueKey="id"
              labelKey="name"
              options={companies}
              value={pigGroupState.company || null}
              type="text"
              placeholder={<FormattedMessage id="general.searchBy.companies" />}
              onChange={(option) => (option !== null ? onGroupInfoChange('company')(option) : null)}
              isLoading={isCompaniesLoading}
            />
          </div>
          <div className={styles['group-field']}>
            <div className={styles.title}>
              <FormattedMessage id="general.farm" />
            </div>
            <AsyncSelectLoadMore
              className="small-12 medium-6"
              selected={pigGroupState.farm || null}
              labelRenderer={formatFarmOptionLabel}
              onChange={onGroupInfoChange('farm')}
              optionsGetter={getInternalFarms}
              placeholder={<FormattedMessage id="general.searchBy.farmNameOrId" />}
            />
          </div>
          <div className={styles['group-field']}>
            <CircleCheckbox
              onChange={onGroupLooseSowHousedToggle}
              checked={pigGroupState.loose_sow_housed}
              label={<FormattedMessage tagName="b" id="general.looseSowHoused" />}
            />
          </div>
          <div className={styles['group-field']}>
            <div className={styles.title}>
              <FormattedMessage id="general.source" />
            </div>

            {isSourcesEditable && (
              <SourcesSelector
                className="small-12 medium-6"
                onChange={onGroupInfoChange('sources')}
                sources={pigGroupState.sources}
              />
            )}

            {!isSourcesEditable && (
              <div className={cn('small-12 medium-6', styles['edit-source-row'])}>
                <span>{pigGroup.external_source?.name || <FormattedMessage id="general.notAvail" />}</span>
                <TransparentBtn onClick={handleConfirmSourcesEdit}>
                  <FormattedMessage id="general.button.edit" />
                </TransparentBtn>
              </div>
            )}
          </div>
          {isABFFieldVisible && (
            <div className={styles['group-field']}>
              <span className={styles.title}>
                <FormattedMessage id="general.abfGroup" />
              </span>
              <div className={cn('small-12 medium-6', styles['abf-field'])}>
                {pigGroupState.abf_tracking ?
                  <FormattedMessage id="general.activeAbfGroup" /> :
                  <FormattedMessage id="general.groupIsNotABF" />
                }
                <ButtonRadioGroup
                  name="abf_tracking"
                  className={styles['radio-button-group']}
                  options={abfOptions}
                  value={pigGroupState.abf_tracking}
                  onChange={onGroupAbfChange}
                />
              </div>
            </div>
          )}
          <div className={styles['group-field']}>
            <Button
              disabled={isSavingDisabled}
              className="mv-20"
              primary
              onClick={onSubmit}
            >
              <FormattedMessage id="general.button.save" />
            </Button>
          </div>
        </Panel.Body>
      </Panel>
    </section>
  );
};

GroupInfo.propTypes = {
  params: T.object.isRequired,
  pigGroup: T.object,
  updatePigGroup: T.func.isRequired,
  openModal: T.func.isRequired,
  isLogisticsEnabled: T.bool.isRequired,
  isLoading: T.bool.isRequired,
  isABFEnabled: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isLogisticsEnabled: state.auth.user.current_company.logistics,
    pigGroup: state.pigGroups.groupShow.data,
    isLoading: state.pigGroups.groupShow.isLoading,
    isABFEnabled: state.auth.user.current_company.abf_tracking,
  }), {
    updatePigGroup,
    openModal,
  }
)(GroupInfo);
