import React, { useEffect, useMemo, useState } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withCorrection from './utils/withCorrectionHOC';
// redux
import { connect } from 'react-redux';
import { reportTreatments, uploadAssetComplete, deleteAsset } from 'reducers/dailyCheckup/checkupEdit';
import { openViewer } from 'reducers/mediaViewer';
import { openPortalRight, closePortalRight } from 'reducers/rightToLeftPortal';
import {
  fetchList as fetchTreatmentsList,
  setDestroyedData,
  setSelectedData,
  selectItem as selectTreatment,
  resetData as resetTreatmentsData,
} from 'reducers/mobileListPicker';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import AttentionBox from 'components/AttentionBox/AttentionBox';
import Button from 'components/Button';
import CenterBox from 'components/CenterBox';
import CommentBox from 'components/CommentBox';
import DailyCheckupEditProtector from './DailyCheckupEditProtector';
import Link from 'react-router/lib/Link';
import MediaUploader from 'components/MediaUploader/MediaUploader';
import MessageBox from 'components/MessageBox';
import MobileListPicker from 'components/MobileListPicker/MobileListPicker';
import MobileTreatmentRow from 'components/DailyCheckups/MobileTreatmentRow';
import NumberStepInput from 'components/NumberStepInput/NumberStepInput';
import OralTreatmentUnit from 'components/DailyCheckups/TreatmentProductLabels/OralTreatmentUnit';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import ReactSelect from 'components/Select/ReactSelect';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import Translation from 'components/Translation';
import TreatmentProtocolsProductSteps from './components/TreatmentProtocolsProductSteps';
import WithdrawalWarningBox from './components/WithdrawalWarningBox';
import PigsUnderTreatmentLine from 'components/PigsUnderTreatmentLine';
// utils
import { animateScroll } from 'react-scroll';
import { getCheckupBackUrls } from 'utils/checkupHelper';
import { getCommentByRole } from 'helpers.js';
import { getMedType, withdrawalParams, formatDosageType } from 'utils';
import { isMobile } from 'react-device-detect';
import { sendNotify } from 'utils/airbrakeHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import update from 'react-addons-update';
// assets
import './DailyCheckupReportTreatments.scss';
// constants
import { comment_roles, API_URL } from 'constants.js';

const buildHeadsCountData = (treatments) => treatments
  .filter(({ treatment_product_id, _destroy }) => treatment_product_id && !_destroy)
  .map(({ head_count }) => head_count)
  .sort((curr, next) => (curr - next));

const calcTotalHeadCount = (treatmentsHeads) => {
  if (!treatmentsHeads.length) return 0;
  if (treatmentsHeads.length === 1) return treatmentsHeads[0];
  return Math.max(...treatmentsHeads);
};

const DailyCheckupReportTreatments = ({
  checkup,
  closePortalRight,
  currentUser,
  deleteAsset,
  destroyedTreatments,
  fetchTreatmentsList,
  isEditMode,
  isOnline,
  isReportingTreatments,
  location,
  makeCheckupCorrection,
  measurementSystem,
  openPortalRight,
  openViewer,
  params,
  reportTreatments,
  resetTreatmentsData,
  selectedTreatments,
  selectTreatment,
  setDestroyedData,
  setPageOptions,
  setSelectedData,
  treatmentsList,
  uploadAssetComplete,
  validateCheckup,
  withdrawalTrackingOn,
}, { router }) => {
  const [comment, setComment] = useState('');
  const [mentionsIds, setMentionsIds] = useState([]);
  const [headCount, setHeadCount] = useState(0);
  const [selectedTreatmentIndex, setSelectedTreatmentIndex] = useState();
  const { media_upload } = currentUser.current_company;
  const isAdmin = currentUser.roles_map.admin;

  const setInitialData = () => {
    if (isEmpty(checkup)) {
      return;
    }

    const { TREATMENTS_ROLE } = comment_roles;
    const { comment, mentioned_user_ids } = getCommentByRole(checkup.comments || [], TREATMENTS_ROLE);
    const { prevPageUrl } = getCheckupBackUrls(checkup, location);

    setComment(comment || '');
    setMentionsIds(mentioned_user_ids || []);
    setHeadCount(checkup.total_pigs_treated);

    setPageOptions({
      mobileTitle: 'reportMedications',
      backLink: { link: prevPageUrl, icon: 'fa fa-times fs-18' },
      inEditMode: isEditMode,
    });

    if (!checkup.dc_treatments.length) {
      setSelectedData(isMobile ? [] : [{}]);
    } else {
      const dcTreatments = checkup.dc_treatments.map((treatment) => ({
        ...treatment,
        quantity: parseFloat(treatment.quantity).toFixed(2) || null,
        gallons_value: parseFloat(treatment.gallons_value) || null,
      }));
      setSelectedData(dcTreatments);
    }
  };

  useEffect(() => {
    setInitialData();
    fetchTreatmentsList('/treatment_products');

    return () => {
      resetTreatmentsData();
      setPageOptions({ mobileTitle: '' });
    };
  }, []);

  useEffect(() => {
    setInitialData();
  }, [checkup.id]);

  useEffect(() => {
    const treatmentsHeadsArr = buildHeadsCountData(selectedTreatments);
    const value = calcTotalHeadCount(treatmentsHeadsArr);
    setHeadCount(value);
  }, [selectedTreatments]);

  const onMobileTreatmentChange = (treatment, selectedTreatmentIndex) => {
    const destroyed = { ...selectedTreatments[selectedTreatmentIndex], _destroy: true };
    setDestroyedData([...destroyedTreatments, destroyed]);
    const treatments = update(selectedTreatments, {
      [selectedTreatmentIndex]: {
        $set: {
          treatment_product_id: treatment.id,
          treatment_product: treatment,
          head_count: (treatment.vaccine || treatment.route.includes('oral')) ? (checkup?.pigs || 0) : 0,
          quantity: 0,
          skip_efficacy_survey: false,
        },
      },
    });
    setSelectedData(treatments);
    closePortalRight();
  };

  const onMobileTreatmentAdd = (treatmentProduct) => {
    const { id, route, vaccine, dosage_measure, dosage_type } = treatmentProduct;
    const initialHeadCount = dosage_measure === 'head' && dosage_type === 'ml' ? 1 : checkup?.pigs || 0;
    const headCount = (vaccine || route.includes('oral')) ? initialHeadCount : 0;
    const overrideAttrs = { treatment_product: treatmentProduct, quantity: 0.00, head_count: headCount };

    // checking if treatment with added product was previosly destroyed, if it was - mark it as undestroyed and use it's values for added one
    const destroyedTreatment = destroyedTreatments.find(({ treatment_product_id }) => treatment_product_id === id);
    const isTreatmentPreviouslyDestroyed = !!destroyedTreatment;
    const addedTreatment = isTreatmentPreviouslyDestroyed
      ? { ...destroyedTreatment, _destroy: undefined }
      : { treatment_product_id: id, skip_efficacy_survey: false };

    selectTreatment({ ...addedTreatment, ...overrideAttrs });

    // if treatment with added product was previosly destroyed - remove it from destroyed list
    if (isTreatmentPreviouslyDestroyed) {
      setDestroyedData(destroyedTreatments
        .filter(({ treatment_product_id }) => (treatment_product_id !== destroyedTreatment.treatment_product_id)));
    }
  };

  const renderMobileListRow = (selectedIndex) => (treatment, mode, { isSelected, isDisabled }) => {
    const onRowClick = {
      change: () => onMobileTreatmentChange(treatment, selectedIndex),
      add: () => onMobileTreatmentAdd(treatment),
      select: () => onMobileTreatmentAdd(treatment),
    }[mode];

    const selectedRow = isSelected(treatment);
    const disabledRow = isDisabled(treatment);
    const actionBtnType = mode === 'change' ? 'button' : 'checkbox';

    return (
      <MobileTreatmentRow
        key={treatment.id}
        treatment={treatment}
        actionBtnType={actionBtnType}
        disabledRow={disabledRow}
        onRowClick={onRowClick}
        selectedRow={selectedRow}
        abfTracking={checkup.abf_tracking}
      />
    );
  };

  const addRow = () => {
    if (!isMobile) {
      setSelectedData([...selectedTreatments, {}]);
      return;
    }

    openPortalRight(
      <MobileListPicker
        title={<FormattedMessage id="general.pageTitle.addMedications" />}
        mode="add"
        idKey="treatment_product_id"
        rightButtonOptions={{ label: 'next' }}
        rowRenderer={renderMobileListRow()}
      />
    );
  };

  const removeRow = (index) => () => {
    if (selectedTreatments[index].id) {
      const destroyData = [];
      destroyData.push({ ...selectedTreatments[index], _destroy: true });
      setDestroyedData([...destroyedTreatments, ...destroyData]);
    }
    setSelectedData(update(selectedTreatments, { $splice: [[index, 1]] }));
  };

  const onTreatmentChange = (index, treatmentProduct) => {
    if (treatmentProduct) {
      const { id, vaccine, route, dosage_measure, dosage_type } = treatmentProduct;
      const initialHeadCount = dosage_measure === 'head' && dosage_type === 'ml' ? 1 : checkup?.pigs || 0;
      const headCount = (vaccine || route.includes('oral')) ? initialHeadCount : 0;

      // checking if treatment with added product was previosly destroyed, if it was - mark it as undestroyed and use it's values for added one
      const destroyedTreatment = destroyedTreatments.find(({ treatment_product_id }) => treatment_product_id === id);
      const isTreatmentPreviouslyDestroyed = !!destroyedTreatment;
      const newTreatment = isTreatmentPreviouslyDestroyed ?
        { ...destroyedTreatment, _destroy: undefined } :
        {
          id: !selectedTreatments.length ? undefined : selectedTreatments[index].id,
          treatment_product_id: parseInt(id, 10),
          skip_efficacy_survey: false
        };
      const overrideAttrs = { treatment_product: treatmentProduct, quantity: 0.00, head_count: headCount };
      const newTreatments = update(selectedTreatments, { [index]: { $set: { ...newTreatment, ...overrideAttrs } } });
      setSelectedData(newTreatments);

      // if treatment with added product was previosly destroyed - remove it from destroyed list
      if (isTreatmentPreviouslyDestroyed) {
        setDestroyedData(destroyedTreatments.filter(({ treatment_product_id }) => (
          treatment_product_id !== destroyedTreatment.treatment_product_id)));
      }
    }
  };

  const addProtocolTreatment = (treatmentProtocolStep) => {
    const { id, treatment_protocol_product: { treatment_product } } = treatmentProtocolStep;
    const { vaccine, route, dosage_measure, dosage_type } = treatment_product;
    const defaultValue = dosage_measure === 'head' && dosage_type === 'ml' ? 1 : checkup?.pigs || 0;
    const headCount = (vaccine || route.includes('oral')) ? defaultValue : 0;
    const treatment = {
      quantity: 0.00,
      treatment_product,
      treatment_product_id: parseInt(treatment_product.id, 10),
      treatment_protocol_product_step_id: id,
      skip_efficacy_survey: false,
      head_count: headCount,
    };
    const newTreatments = selectedTreatments.length === 1 && isEmpty(selectedTreatments[0])
      ? [treatment]
      : [...selectedTreatments, treatment];

    setSelectedData(newTreatments);
  };


  const parseDecimalText = (text, prev) => {
    const pattern = /^[+-]?\d+(\.\d+)?$/; // float number
    if (text === '') return text;
    if (!`0${text}0`.match(pattern)) return prev;
    return text;
  };

  const onDetailInputChange = (value, key, index) => {
    const { initial_pigs, pigs } = checkup;
    const pigsAmount = Math.max(initial_pigs, pigs) || 0;
    const prev = selectedTreatments[index]?.[key];
    let newValue = ['gallons_value'].includes(key)
      ? parseDecimalText(value, prev)
      : parseInt(value, 10);
    if (newValue !== prev) {
      if (key === 'head_count') {
        newValue = newValue <= pigsAmount ? newValue : pigsAmount;
      }
      const treatments = update(selectedTreatments, {
        [index]: { $merge: { [key]: parseFloat(newValue) } },
      });
      setSelectedData(treatments);
    }
  };

  const optionRenderer = (option) => {
    const className = getMedType(option);

    return (
      <span className={`medication ${className} ${option.divider ? 'divider' : ''}`}>
        <span>
          <i className={`fa fa-${className}`} />
          {option.name}
        </span>
        <span className="right-text">
          {option.common && <FormattedMessage id="general.meds.common" />}
          {option.vaccine && <>&nbsp;(<FormattedMessage id="general.vaccine" />)</>}
          {checkup.abf_tracking && (
            <>
              {option.safe && <>&nbsp;(<FormattedMessage id="general.safe" />)</>}
              {!option.safe && <>&nbsp;(<FormattedMessage id="general.unsafe" />)</>}
            </>
          )}
        </span>
      </span>
    );
  };

  const onMobileModeChange = (index) => {
    setSelectedTreatmentIndex(index);
    openPortalRight(
      <MobileListPicker
        title={<FormattedMessage id="general.pageTitle.changeMedication" />}
        mode={selectedTreatments.length ? 'change' : 'add'}
        rightButtonOptions={selectedTreatments.length ? undefined : { label: 'next' }}
        idKey="treatment_product_id"
        rowRenderer={renderMobileListRow(index)}
      />
    );
  };

  useEffect(() => {

  }, [selectedTreatmentIndex]);

  const submitData = () => {
    // validateCheckup is declared in DailyCheckupContainer
    if (!validateCheckup(checkup)) {
      return;
    }

    const filteredList = selectedTreatments.filter((item) => item.treatment_product_id);
    const destroyedFilteredList = destroyedTreatments.filter(({ id, _destroy }) => !!id && !!_destroy);

    const unsafeTreatedPigs = checkup.abf_tracking
      ? selectedTreatments
        .filter(({ treatment_product }) => !treatment_product?.safe)
        .map(({ head_count }) => head_count || 0)
        .reduce((prev, next) => prev + next, 0)
      : 0;

    const resource = {
      dc_treatments: [...filteredList, ...destroyedFilteredList],
      comments_treatments: { text: comment, mentioned_user_ids: mentionsIds },
      total_pigs_treated: headCount,
      unsafe_pigs_treated: unsafeTreatedPigs > checkup.pigs ? checkup.pigs : unsafeTreatedPigs,
    };

    const { checkupUrl } = getCheckupBackUrls(checkup, location);

    const submitRequest = isEditMode
      ? makeCheckupCorrection(checkup.id, resource)
      : reportTreatments(checkup.id, { resource });

    submitRequest
      .then(() => router.push(checkupUrl))
      .catch((response) => {
        sendNotify(response, currentUser);
        return toastResponseErrors(response);
      });
  };

  const getUnselectedTreatments = (selectedTreatment) => {
    return treatmentsList.filter(({ id }) => (
      id === selectedTreatment.treatment_product_id ||
      !selectedTreatments.some((selected) => selected.treatment_product_id === id)));
  };

  const validateHeadCount = (headCountArr, value) => {
    const { initial_pigs, pigs } = checkup;
    const pigsAmount = Math.max(initial_pigs, pigs) || 0;

    if (headCountArr.length === 1) return headCountArr[0];

    const minHeadTreatment = Math.min(...headCountArr);

    if (value < minHeadTreatment) return minHeadTreatment;
    if (value > pigsAmount) return pigsAmount;
    return value;
  };

  const handleSetHeadCount = (val) => {
    const value = Number.isInteger(val) ? val : parseInt(val.target.value, 10);
    const input = document.querySelector('#head-total-count');
    const isFocused = (document.activeElement === input);
    const headCountArr = buildHeadsCountData(selectedTreatments);

    if (!headCountArr.length) return;

    const headCountValue = isFocused && headCountArr.length > 1
      ? value
      : validateHeadCount(headCountArr, value);

    if (headCount !== headCountValue) setHeadCount(headCountValue);
  };

  const setHeadCountBlur = (e) => {
    const value = parseInt(e.target.value, 10);
    const headCountArr = buildHeadsCountData(selectedTreatments);

    if (!headCountArr.length) return;

    setHeadCount(validateHeadCount(headCountArr, value));
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const onCommentOpen = () => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  };

  const handleBlur = ({ target: { value } }, key, index) => {
    const { pigs, initial_pigs } = checkup;
    const pigsAmount = Math.max(pigs, initial_pigs) || 0;
    const prev = selectedTreatments[index]?.[key];
    let newValue = parseInt(value, 10);
    if (newValue !== prev) {
      if (key === 'head_count') newValue = newValue <= pigsAmount ? newValue : pigsAmount;
      const treatments = update(selectedTreatments, {
        [index]: { $merge: { [key]: parseFloat(newValue) } },
      });
      setSelectedData(treatments);
    }
  };

  const onTreatmentQuantityChange = (index, value) => {
    const prev = selectedTreatments[index]?.quantity;
    const newValue = parseDecimalText(value, prev);
    if (newValue !== prev) {
      const treatments = update(selectedTreatments, { [index]: { $merge: { quantity: newValue } } });
      setSelectedData(treatments);
    }
  };

  const onTreatmentQuantityBlur = ({ target: { value } }, index) => {
    const currentValue = selectedTreatments[index]?.quantity;
    const newValue = Number.isInteger(+value) ? +value : parseFloat(value).toFixed(2);
    if (newValue !== currentValue) {
      const treatments = update(selectedTreatments, {
        [index]: { $merge: { quantity: Number.isNaN(+newValue) ? '' : newValue } }
      });
      setSelectedData(treatments);
    }
  };

  const needWithdrawalWarningMessage = (treatmentProduct) => {
    const { value, period } = withdrawalParams(treatmentProduct);
    const isWithdrawalWarnActual = moment(checkup.created_on).add(value, period) >= moment();

    return withdrawalTrackingOn && isWithdrawalWarnActual;
  };

  const getTreatmentLabel = (treatmentProduct) => {
    if (!treatmentProduct) return null;
    const { route, dosage_measure } = treatmentProduct;
    const dosage_type = formatDosageType(treatmentProduct.dosage_type, measurementSystem);
    const type = <FormattedMessage id={`general.short.${dosage_type}`} />;

    if (route.includes('oral')) {
      const msgKey = dosage_measure === 'head' ? 'oralLabel' : 'unitsAdministered';
      return (
        <FormattedMessage id={`container.checkupEdit.reportMedications.${msgKey}`} values={{ type }} />
      );
    }

    return (
      <FormattedMessage
        id="container.checkupEdit.reportMedications.injectableLabel"
        values={{ type, measure: <FormattedMessage id={`general.${dosage_measure}`} /> }}
      />
    );
  };

  const handleDeleteAsset = (assetId) => {
    if (!validateCheckup(checkup)) return;
    deleteAsset(checkup.id, assetId).catch(toastResponseErrors);
  };

  const isContinueDisabled = useMemo(() => {
    if (selectedTreatments.length === 0) {
      return !checkup.treatment_count;
    }

    const pigsAmount = Math.max(checkup?.initial_pigs, checkup?.pigs) || 0;

    return selectedTreatments.some(({
      gallons_value,
      head_count,
      quantity,
      treatment_product_id,
      treatment_product,
    }) => (
      !treatment_product_id ||
        !head_count ||
        (head_count > pigsAmount) ||
        ((treatment_product.dosage_measure === 'gal') && !gallons_value) ||
        ((getMedType(treatment_product) !== 'topical') && !parseFloat(quantity))
    ));
  }, [selectedTreatments, checkup]);
  const hideTrash = (selectedTreatments.length === 1 && !selectedTreatments[0].treatment_product_id) ||
    !selectedTreatments.length;
  const { prevPageUrl } = getCheckupBackUrls(checkup, location);

  const initialUnsafeTreatedPigs = checkup.abf_tracking
    ? checkup.dc_treatments
      ?.filter(({ treatment_product }) => !treatment_product?.safe)
      .map(({ head_count }) => head_count || 0)
      .reduce((prev, next) => prev + next, 0)
    : 0;

  const treatedPigs = checkup.treated_pigs - initialUnsafeTreatedPigs;

  const unsafeTreatedPigs = checkup.abf_tracking
    ? selectedTreatments
      .filter(({ treatment_product }) => !treatment_product?.safe)
      .map(({ head_count }) => head_count || 0)
      .reduce((prev, next) => prev + next, 0)
    : 0;

  let totalTreatedPigs = treatedPigs + unsafeTreatedPigs;
  totalTreatedPigs = totalTreatedPigs > checkup.pigs ? checkup.pigs : totalTreatedPigs;

  return (
    <CenterBox
      isLoading={isReportingTreatments}
      renderIf={Boolean(checkup)}
      className="DailyCheckupReportTreatments"
    >
      <DailyCheckupEditProtector pigGroup={checkup.pig_group}>
        <section className="center-box-header show-for-large">
          <div>
            <div className="subtitle">{checkup.pig_group_name}</div>
            <h1 className="title"><FormattedMessage id="general.medications" /></h1>
          </div>
          <Link to={prevPageUrl}><i className="fa fa-times close-center-box" /></Link>
        </section>
        <PigsUnderCareLine mobile pigsCount={checkup.pigs} />
        {checkup.abf_tracking && <PigsUnderTreatmentLine mobile pigsCount={totalTreatedPigs} />}
        <section className="center-box-body">
          {isEditMode && (
            <MessageBox type="warning">
              <strong><FormattedMessage id="component.alertBox.warning" />&nbsp;</strong>
              <FormattedMessage id="container.checkupEdit.editWarning" />
            </MessageBox>
          )}
          {isOnline && !isEditMode && (
            <TreatmentProtocolsProductSteps
              checkupId={checkup.id}
              pigGroup={checkup.pig_group}
              selectedStepIds={selectedTreatments
                .map((treatment) => treatment.treatment_protocol_product_step_id)
                .filter(Boolean)
              }
              onReport={addProtocolTreatment}
            />
          )}
          <div className="treatment-rows">
            {(selectedTreatments.length ? selectedTreatments : [{}]).map((treatment, index) => {
              const {
                treatment_product_id,
                treatment_product,
                head_count,
                quantity,
                gallons_value,
                treatment_protocol_product_step_id,
              } = treatment;

              const isTreatmentProtocolProduct = !!treatment_protocol_product_step_id;

              return (
                <div
                  key={`treatment-${index}-${treatment_product_id}`}
                  className={cn('treatment-row', { 'disabled': isTreatmentProtocolProduct })}
                >
                  <div className="select-treatment-line">
                    <div className="index-cell">
                      <div className="row-index">{index + 1}</div>
                    </div>
                    <div className="select-treatment-wrapper">
                      {isMobile ? (
                        <div
                          className="mobile-treatment-input"
                          onClick={isTreatmentProtocolProduct ? undefined : () => onMobileModeChange(index)}
                        >
                          <div className="treatment-name">
                            {treatment_product?.name || (
                            <span className={cn('placeholder')}>
                              <FormattedMessage id="general.pageTitle.selectMedications" />
                            </span>
                              )}
                          </div>
                          <i className="change-treatment-icon fa fa-angle-right-btb" />
                        </div>
                      ) : (
                        <ReactSelect
                          placeholder={<FormattedMessage id="general.pageTitle.selectMedications" />}
                          options={getUnselectedTreatments(treatment)}
                          valueKey="id"
                          labelKey="name"
                          arrowRenderer={() => <i className="fa fa-angle-down" />}
                          value={treatment_product_id}
                          onChange={(value) => onTreatmentChange(index, value)}
                          resetValue={{}}
                          disabled={isTreatmentProtocolProduct}
                          clearable={false}
                          optionRenderer={optionRenderer}
                        />
                      )}
                    </div>
                    <div
                      className={cn('trash-cell', {
                        'hidden': hideTrash || (isTreatmentProtocolProduct && (!treatment.id || !isAdmin))
                      })}
                    >
                      <i className="fa fa-trash-o trash-icon" onClick={removeRow(index)} />
                    </div>
                  </div>

                  {isTreatmentProtocolProduct && (
                    <MessageBox type="default" icon={<i className="fa fa-info-circle" />}>
                      <FormattedMessage id="general.productIsAppliedAccordingToTreatmentProtocol" />
                    </MessageBox>
                  )}

                  {treatment_product_id && needWithdrawalWarningMessage(treatment_product)
                  && (<WithdrawalWarningBox treatmentProduct={treatment_product} checkup={checkup} />)}
                  {treatment_product_id && treatment_product.dosage_type === 'unit' && (
                    <AttentionBox className="oral-attention">
                      <FormattedMessage id="general.unitSize" />&nbsp;•&nbsp;
                      <OralTreatmentUnit product={treatment_product} />
                    </AttentionBox>
                  )}
                  {treatment_product_id && treatment_product.notes && (
                    <AttentionBox showIcon={false} className="oral-attention">
                      <span>
                        <b><FormattedMessage id="general.notes" /> •&nbsp;</b>
                        <Translation text={treatment_product.notes}>
                          {treatment_product.notes}
                        </Translation>
                      </span>
                    </AttentionBox>
                  )}
                  {treatment_product_id && (
                    <div
                      className={cn(
                        'select-values-line',
                        { 'skip-margin-bottom': treatment_product.efficacy_survey && !isEditMode }
                      )}
                    >
                      <div className="index-cell" />
                      <div className="treatment-details">
                        <div className="treatment-details-row">
                          <i className="fa fa-exclamation-triangle-bts icon" />
                          <NumberStepInput
                            className="treatment-number-input"
                            label={<FormattedMessage id="container.checkupEdit.reportMedications.headTreated" />}
                            value={head_count || ''}
                            onChange={(value) => {
                              onDetailInputChange(value, 'head_count', index);
                            }}
                            onFocus={handleFocus}
                            onBlur={(e) => handleBlur(e, 'head_count', index)}
                          />
                        </div>
                        {!treatment_product.route.includes('topical') && (
                          <div className="treatment-details-row">
                            <i
                              className={cn(
                                'fa fa-exclamation-triangle-bts icon',
                                { 'visible': treatment_product.vaccine && (quantity === 0) },
                              )}
                            />
                            <NumberStepInput
                              label={getTreatmentLabel(treatment_product)}
                              value={
                                quantity ||
                                (treatment_product.dosage_measure === 'head' && treatment_product.dosage_type === 'ml' ?
                                  0.25 :
                                  0)
                              }
                              onChange={(value) => onTreatmentQuantityChange(index, value)}
                              onFocus={handleFocus}
                              onBlur={(e) => onTreatmentQuantityBlur(e, index)}
                              hasWarning={treatment_product.vaccine && (quantity === 0)}
                              className="treatment-number-input"
                              step={isMobile ? 0.1 : 0.25}
                              valueParser={(val) => val}
                              normalizeValue={(value) => value.toString()}
                            />
                          </div>
                        )}
                        {treatment_product.dosage_measure === 'gal' &&
                        !treatment_product.route.includes('topical') && (
                          <div className="treatment-details-row">
                            <i
                              className={cn(
                                'fa fa-exclamation-triangle-bts icon',
                                { 'visible': treatment_product.vaccine && (quantity === 0) },
                              )}
                            />
                            <NumberStepInput
                              className="treatment-number-input"
                              label={<FormattedMessage id="general.gallonsWater" />}
                              value={gallons_value || ''}
                              onChange={(value) => onDetailInputChange(value, 'gallons_value', index)}
                              onFocus={handleFocus}
                              onBlur={(e) => handleBlur(e, 'gallons_value', index)}
                            />
                          </div>
                        )}
                      </div>
                      <div className={cn('trash-cell', { 'hidden': hideTrash })} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {!!selectedTreatments.length && (
            <button className="add-treatment" onClick={addRow}>
              <FormattedMessage id="container.checkupEdit.reportMedications.add" />
            </button>
          )}

          <CommentBox
            hasMentions
            disabledIds={mentionsIds}
            entityId={checkup?.pig_group?.farm_id}
            entityType="farm"
            onOpen={onCommentOpen}
            onValueChange={setComment}
            onMentionsChange={(mentions) => { setMentionsIds(mentions.map((i) => i.id)); }}
            value={comment}
            isOpen={!!comment}
            placeholder=""
          />

          <div className="treatment-details">
            <div className="treatment-details-row total-count">
              <i className="fa fa-exclamation-triangle-bts icon visible" />
              <NumberStepInput
                label={<FormattedMessage id="container.checkupEdit.reportMedications.totalTreated" />}
                value={headCount}
                onChange={handleSetHeadCount}
                onFocus={handleFocus}
                onBlur={setHeadCountBlur}
                hasWarning
                className="treatment-number-input always-show-warning"
                id="head-total-count"
              />
            </div>
          </div>

          {+params.id === checkup?.id && media_upload && (
            <MediaUploader
              checkup={checkup}
              customUploadPath={`${API_URL}/daily_checkups/${params.id}/chunk?category=treatments`}
              deleteAsset={handleDeleteAsset}
              filterAssets={(asset) => asset.category === 'treatments'}
              headerLess
              openViewer={openViewer}
              paramsCheckupId={params.id}
              uploadAssetComplete={uploadAssetComplete}
              validateCheckup={validateCheckup}
            />
          )}

        </section>

        <section className="center-box-footer show-for-large">
          <div className="buttons text-right">
            <Link className="button margin-right-1" to={prevPageUrl}>
              <FormattedMessage id="general.button.cancel" />
            </Link>
            <Button
              primary
              disabled={isContinueDisabled || isReportingTreatments}
              className="submit wider"
              onClick={submitData}
            >
              <FormattedMessage id="general.button.continue" />
            </Button>
          </div>
        </section>

        <StickyFooter className="hide-for-large" hidden={isContinueDisabled}>
          <Button
            stickyFooter
            primary
            disabled={isContinueDisabled || isReportingTreatments}
            onClick={submitData}
          >
            <FormattedMessage id="general.button.continue" />
          </Button>
        </StickyFooter>
      </DailyCheckupEditProtector>
    </CenterBox>
  );
};

DailyCheckupReportTreatments.contextTypes = {
  router: T.object.isRequired,
};

DailyCheckupReportTreatments.propTypes = {
  checkup: T.object,
  closePortalRight: T.func.isRequired,
  currentUser: T.object,
  deleteAsset: T.func.isRequired,
  destroyedTreatments: T.array.isRequired,
  fetchTreatmentsList: T.func.isRequired,
  isEditMode: T.bool,
  isOnline: T.bool.isRequired,
  isReportingTreatments: T.bool.isRequired,
  location: T.object.isRequired,
  makeCheckupCorrection: T.func,
  measurementSystem: T.string,
  openPortalRight: T.func.isRequired,
  openViewer: T.func.isRequired,
  params: T.object.isRequired,
  reportTreatments: T.func.isRequired,
  resetTreatmentsData: T.func.isRequired,
  selectedTreatments: T.array.isRequired,
  selectTreatment: T.func.isRequired,
  setDestroyedData: T.func.isRequired,
  setPageOptions: T.func,
  setSelectedData: T.func.isRequired,
  treatmentsList: T.array.isRequired,
  uploadAssetComplete: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  withdrawalTrackingOn: T.bool,
};

export default compose(
  connect((state, props) => ({
    currentUser: state.auth.user,
    destroyedTreatments: state.mobileListPicker.destroyed,
    isOnline: state.network.isOnline,
    isReportingTreatments: props.isLoading || state.dailyCheckup.checkupEdit.isReportingTreatments,
    measurementSystem: state.auth.user.current_company.measurement_system,
    selectedTreatments: state.mobileListPicker.selected,
    treatmentsList: state.mobileListPicker.dataList.resources,
    withdrawalTrackingOn: state.auth.user.current_company.withdrawal_tracking_on,
  }), {
    closePortalRight,
    deleteAsset,
    fetchTreatmentsList,
    openPortalRight,
    openViewer,
    reportTreatments,
    resetTreatmentsData,
    selectTreatment,
    setDestroyedData,
    setPageOptions,
    setSelectedData,
    uploadAssetComplete,
  }),
  withCorrection,
)(DailyCheckupReportTreatments);
